<template>
  <v-dialog
    v-model="openOrderAdjustmentRecordDialog" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="訂單調整紀錄" />
        <v-btn @click="handleOrderAdjustmentRecordDialogSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container>
        <v-row class="px-3 pt-4">
          <v-col cols="12" class="py-0 px-0 mb-3" v-for="(recordV, recordI) in adjustmentData" :key="recordI" @click="clickOpenRecordDetail(recordV)">
            <div class="recordItem d-flex justify-space-between border-primary" :class="{'provider': recordV.client === 'provider', 'store': recordV.client === 'store'}">
              <span>{{ recordV.client === 'provider' ? '供應商' : '商店' }}調整</span>
              <span>{{ Moment(recordV.dateTime).format('YYYY-MM-DD') }}</span>
              <span :class="{'reject': recordV.status === 'reject', 'reviewing': recordV.status === ''}">{{ getStatusName(recordV.status) }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <order-adjustment
      :openOrderAdjustmentDialog="openOrderAdjustmentDialog"
      :orderData="editOrderData || {}"
      :providerId="providerId"
      :storeId="storeId"
      :isDisplayMode="true"
      @updateOrderAdjustmentDialogSwitch="(isOpen) => openOrderAdjustmentDialog = isOpen"
    />
  </v-dialog>
</template>

<script>
import Moment from 'moment'
import OrderAdjustment from "@/modules/store/views/orderDetail/OrderAdjustment";
export default {
  components: {
    // "block-title": () => import('@/modules/base/components/blockTitle.vue'),
    OrderAdjustment
  },
  props: {
    openOrderAdjustmentRecordDialog: {
      type: Boolean,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
    adjustmentData: {
      type: Array,
      required: true,
    },
    providerId: {
      type: String,
      required: true,
    },
    storeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    Moment: Moment,
    openOrderAdjustmentDialog: false,
    editOrderData: null
  }),
  methods: {
    getStatusName(status) {
      if(status === "") return '待審核'
      if(status === "apply") return '已接受'
      if(status === "reject") return '已拒絕'
    },
    handleOrderAdjustmentRecordDialogSwitch(isOpen) {
      this.$emit('updateOrderAdjustmentRecordDialogSwitch', isOpen)
    },
    clickOpenRecordDetail(record) {
      this.editOrderData = window.eagleLodash.cloneDeep(this.orderData)
      this.editOrderData.items = this.editOrderData.items.map(itemV => {
        record.items.forEach(adjustmentV => {
          if(adjustmentV.product_id === itemV.product_id && adjustmentV.new_count !== adjustmentV.old_count){
            itemV.modifyCount = adjustmentV.new_count
          }
        })
        return itemV
      })
      this.openOrderAdjustmentDialog = true
    }
  },
};
</script>
<style scoped lang="sass">
.recordItem
  border: solid 1px #006DE0
  border-radius: 5px
  padding: 12px 10px
  &.provider
    border-color: #009775
    color: #009775
  &.store
    border-color: #006DE0
    color: #006DE0
  span
    &.reject
      color: #e3342f
    &.reviewing
      opacity: 0
</style>
